export const getArticleMarkupForSeoS1 = (page) => {
  return {
    "@type": "Article",
    "headline": page.h1,
    "datePublished": page.createdAt,
  }
}

export const contextForAllPages = {
  "@context": "https://schema.org",
}

export const getArticleMarkupForPageNewUi = (page) => {
  return { 
    "@type": "Article",
    "headline": "Personal loans made for everyone",
    "datePublished": page.createdAt,
  }
}

export const getArticleMarkupForLegalPage = (page) => {
  return { 
    "@type": "Article",
    "headline": page.heading,
    "datePublished": page.createdAt,
  }
}