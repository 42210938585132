import React from 'react'
import styled from 'styled-components'

const BlueHeader = ({headerText, children}) => {
  return (
    <BlueHeaderWrapper>
      <Text>
        <h1>{headerText}</h1>
        <div>
          {children}  
        </div>
      </Text>
    </BlueHeaderWrapper>
  )
}

const BlueHeaderWrapper = styled.div`
  width: 100%;
  background: linear-gradient(145deg, rgb(38 75 101) 60%, rgb(46 84 111) 0);
  padding: 28px 0;
  @media screen and (max-width: 991px) {
    width: 100%;
  };

  .alink{
    color: #fff;
    font-family: QuicksandBold;
    text-decoration: underline;
  }
`

const Text = styled.div`
  margin: 60px auto;
  h1 {
    color: white;
    margin: 12px 0;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 42px;
	  };

  };
  div {
    font-size: 18px;
    color: white;
    margin: 12px 0;
    text-align: center;
  }
`


export default BlueHeader