import React from 'react'
import { graphql } from 'gatsby'
import BlueHeader from '../components/UI/Header/BlueHeader'
import Layout from '../layouts/layout'
import ContentContainer from '../components/UI/ContentContainer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Seo from '../components/seo'
import { contextForAllPages, getArticleMarkupForLegalPage } from '../support/structuredDataConfig/seoS1'

const LegalPageTemplate = ({ data, path }) => {
  const {
    heading,
    subheading,
    pageContent,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaImage
  } = data.contentfulPageLegalPages

  const structuredData = {
    ...contextForAllPages,
    ...getArticleMarkupForLegalPage(data.contentfulPageLegalPages)
  }

  return (
    <Layout hasGetYourRate={false}>
      <Seo title={seoMetaTitle} description={seoMetaDescription} image={seoMetaImage?.fluid?.src} path={path} structuredData={structuredData}/>
      <BlueHeader headerText={heading}>
        <p>{subheading}</p>
      </BlueHeader>
      <ContentContainer>
        <div>{renderRichText(pageContent, options)}</div>
      </ContentContainer>
    </Layout>
  )
}

export default LegalPageTemplate

const options = {
  // Have your styles overridden here...
}

export const pageQuery = graphql`
  query LegalPage(
    $slug: String!
  ) {
    contentfulPageLegalPages(slug: { eq: $slug }) {
      heading
      subheading
      createdAt
      pageContent {
        raw
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaImage {
        fluid {
          src
        }
      }
    }
  }
`
